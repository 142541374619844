import { component } from 'picoapp';
import choozy from 'choozy';
import { on } from '@selfaware/martha';

export default component((node) => {
  const { addToCart, bulkOptions, promo } = choozy(node);

  const addToCartText = addToCart.dataset.addToCartText;
  const promoEnabled = promo ? promo.dataset.promoEnabled === 'true' : false;

  // Accordion toggle logic
  document.querySelectorAll('.accordion-button').forEach(button => {
    button.addEventListener('click', () => {
      const content = button.parentElement.nextElementSibling;
      button.classList.toggle('active');

      if (button.classList.contains('active')) {
        content.style.display = 'block';
      } else {
        content.style.display = 'none';
      }
    });
  });

  const products = JSON.parse(bulkOptions.dataset.stripeProductInfo);
  const shippingRates = JSON.parse(bulkOptions.dataset.stripeShippingInfo);
  const redirectSlug = JSON.parse(bulkOptions.dataset.stripeRedirectSlug);


  // Update price display function
  function updatePriceDisplay() {
    const selectedPack = document.querySelector('input[name="packOption"]:checked');
    if (!selectedPack) {
      console.warn("No pack selected.");
      return;
    }

    const priceDisplay = document.querySelector('.total-price-display');
    const discountAppliedDisplay = document.querySelector('.discount-applied-display');
    const fullPrice = parseFloat(selectedPack.dataset.fullPrice) / 100;
    const discountRate = parseFloat(selectedPack.dataset.discount);

    const isSubscription = document.querySelector('input[name="subscription"]').checked;

    // Apply subscription discount as an additional percentage off the already discounted price
    const discountedPrice = fullPrice * (1 - discountRate);
    const finalPrice = isSubscription ? discountedPrice * 0.8 : discountedPrice;

    // Update price display
    if (finalPrice === fullPrice) {
      priceDisplay.textContent = `$${finalPrice.toFixed(2)}`;
    } else {
      priceDisplay.innerHTML = `<span>$${finalPrice.toFixed(2)}</span> <span class="strike-center">$${fullPrice.toFixed(2)}</span>`;
    }

    // Update discount applied display (two-line format)
    const lines = [];
    if (discountRate > 0) {
      lines.push(`${(discountRate * 100).toFixed(0)}% OFF Bulk Discount`);
    }
    if (isSubscription) {
      lines.push("20% Subscription Discount Applied on Total Order");
    }

    // Join lines with line breaks and update the display
    discountAppliedDisplay.textContent = lines.length > 0 ? lines.join('\n') : '';
  }

  updatePriceDisplay();

  // Event listeners for price updates
  document.querySelectorAll('input[name="packOption"]').forEach((radio) => {
    radio.addEventListener('change', updatePriceDisplay);
  });

  document.querySelector('input[name="subscription"]').addEventListener('change', updatePriceDisplay);

  // Stripe checkout function
  async function stripeCheckout(product, selectedType, priceAmount, promoEnabled) {
    const priceId = selectedType === 'subscription' ? product.subscriptionPriceId : product.oneTimePriceId;
    const selectedShippingRate = shippingRates.find(
      (rate) =>
        priceAmount >= rate.minPriceAmount &&
        (rate.maxPriceAmount === null || rate.maxPriceAmount === undefined || priceAmount <= rate.maxPriceAmount)
    );

    if (!selectedShippingRate) {
      console.error('No valid shipping rate found for the given priceAmount.');
      return;
    }

    const shippingRateId = selectedShippingRate.shippingRateId;

    try {
      const response = await fetch('/.netlify/functions/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          priceId,
          purchaseType: selectedType,
          promoEnabled,
          shippingRateId,
          redirectSlug,
        }),
      });

      if (!response.ok) {
        console.error('Failed to create checkout session:', response.status, response.statusText);
        throw new Error('Failed to create checkout session');
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error('Error in stripeCheckout:', error);
      throw error; // Rethrow to handle it in the caller
    }
  }

  // Add to cart button logic
  on(addToCart, 'click', () => {
    const selectedPack = document.querySelector('input[name="packOption"]:checked');
    if (!selectedPack) {
      console.warn("No pack selected.");
      return;
    }

    const selectedType = document.querySelector('input[name="subscription"]').checked ? 'subscription' : 'one-time';
    const product = products.find((product) => product.label === selectedPack.value);
    const priceAmount = parseFloat(selectedPack.dataset.price);

    addToCart.disabled = true;
    addToCart.textContent = "Processing...";

    stripeCheckout(product, selectedType, priceAmount, promoEnabled)
      .then((data) => {
        if (data && data.url) {
          window.location.href = data.url;
          addToCart.disabled = false;
          addToCart.textContent = addToCartText;
        } else {
          console.error('No URL returned from the checkout session');
          throw new Error('No URL returned from the checkout session');
        }
      })
      .catch((error) => {
        console.error('Error during checkout process:', error);
        alert('Failed to create checkout session. Please try again.');
        addToCart.disabled = false;
        addToCart.textContent = addToCartText;
      });
  });
});
